import './marquee.scss';
import Marquee from '../../classes/Marquee';

let e = new CustomEvent('marquee_ready');
e.Marquee = Marquee;
document.dispatchEvent(e);

// import('./marquee.scss').then(()=>{
//     console.log('marquee css loaded');
//     document.dispatchEvent(e);
// })